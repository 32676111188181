export const TAG_BRAND = "Brand"
export const TAG_BRANDS = "Brands"
export const TAG_BRANDS_ALL = "BrandsAll"

export const TAG_APP_USER = "AppUser"
export const TAG_APP_USERS = "AppUsers"
export const TAG_APP_USERS_ALL = "AppUsersAll"

export const TAG_ADMIN_USER = "AdminUser"
export const TAG_ADMIN_USERS = "AdminUsers"

export const TAG_PURCHASE_HISTORY = "PurchaseHistory"
export const TAG_PURCHASE_HISTORY_COMMENT = "PurchaseHistoryComment"
export const TAG_PURCHASE_HISTORY_COMMENTS = "PurchaseHistoryComments"

export const TAG_STORE = "Store"
export const TAG_STORES = "Stores"
export const TAG_STORES_ALL = "StoresAll"

export const TAG_ANALYZERS_ALL = "AnalyzersAll"

export const TAG_IRON_HEAD = "IronHead"
export const TAG_IRON_HEADS = "IronHeads"
export const TAG_IRON_HEADS_ALL = "IronHeadsAll"

export const TAG_IRON_SHAFT = "IronShaft"
export const TAG_IRON_SHAFTS = "IronShafts"
export const TAG_IRON_SHAFTS_ALL = "IronHeadsAll"

export const TAG_CLUB_HEADS_ALL = "ClubHeadsAll"

export const TAG_SHAFTS_ALL = "ShaftsAll"

export const TAG_TRAINERS_ALL = "TrainersAll"

export const TAG_DRIVER_HEAD = "DriverHead"
export const TAG_DRIVER_HEADS = "DriverHeads"

export const TAG_DRIVER_SHAFT = "DriverShaft"
export const TAG_DRIVER_SHAFTS = "DriverShafts"
export const TAG_DRIVER_SHAFT_FLEXES = "DriverShaftFlexes"

export const TAG_BALL = "BallInfo"
export const TAG_BALLS = "BallsInfo"

export const TAG_CUSTOMER = "Customer"
export const TAG_CUSTOMERS = "Customers"

export const TAG_AUTH = "AuthUser"

export const TAG_SETTINGS_APPVERSION = "SettingsAppVersion"

export const TAG_BODYANALYSIS_DETAILS = "BodyAnalysis"
export const TAG_BODYANALYSIS_LIST = "BodyAnalysisList"

export const TAG_PAYMENT = "Payment"
export const TAG_PAYMENTS = "Payments"

export const TAG_MATCH_HISTORY = "MatchHistory"
export const TAG_MATCH_HISTORIES = "MatchHistories"

export const TAG_STORE_MEMBERS = "StoreMembers"
