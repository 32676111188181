import { useEffect } from "react"
import { useDispatch } from "react-redux"
import { useTranslation } from "react-i18next"

import { openSnackbar } from "stores/snackbar"

export function handlePromise(
	fn,
	successMsg = "Successfully fulfilled the action",
	errorMsg = "Unable to fulfill the action"
) {
	const { t } = useTranslation()
	const dispatch = useDispatch()

	const ret = fn()

	const { error, isSuccess } = Array.isArray(ret) ? ret[1] : ret

	useEffect(() => {
		if (isSuccess) {
			const successkeys = typeof successMsg === "string" ? [successMsg] : successMsg

			dispatch(openSnackbar("success", t(...successkeys)))
		}
	}, [isSuccess])

	useEffect(() => {
		if (error) {
			const errorkeys = typeof errorMsg === "string" ? [errorMsg] : [error.message || errorMsg]

			dispatch(openSnackbar("error", t(...errorkeys)))
		}
	}, [error])

	return ret
}

export function handleError(fn, ...failMsgKey) {
	const { t } = useTranslation()
	const dispatch = useDispatch()

	const ret = fn()

	const { error } = Array.isArray(ret) ? ret[1] : ret

	useEffect(() => {
		if (error) {
			const keys = failMsgKey.length === 0 ? ["common.error"] : failMsgKey

			dispatch(openSnackbar("error", t(...keys)))
		}
	}, [error])

	return ret
}
