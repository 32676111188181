export const KOREA_LANGUAGE = "kor"
export const ENGLISH_LANGUAGE = "en"
export const DATE_DEFAULT_FORMAT = "yyyy-MM-dd"

export const COLORS = {
	RED: "#F44335",
	BLUE: "#2A5FD1",
	WHITE: "#FFFFFF",
	DARK: "#222222",
	LIGHTGREY: "#7B809A",
	GREY: "#344767",
}

export const PURCHASE_STATUSES = {
	ordered: "info",
	backordered: "info",
	cancelled: "error",
	preparing: "info",
	shipping: "warning",
	complete: "success",
	refunded: "default",
	payment_pending: "secondary",
	payment_cancelled: "primary",
	payment_failed: "error",
}

export const OMIT_ON_CLONE = ["id", "created_at", "updated_at"]
