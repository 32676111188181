import BallIcon from "assets/icons/Ball.png"
import BrandIcon from "assets/icons/Brand.png"
import HeadIcon from "assets/icons/Head.png"
import ShaftIcon from "assets/icons/Shaft.png"
import PurchaseIcon from "assets/icons/dollar.svg"

import {
	ROUTE_ADMIN,
	ROUTE_ADMIN_CREATE,
	ROUTE_ADMIN_EDIT,
	ROUTE_USERS_APP,
	ROUTE_BALLS,
	ROUTE_BALLS_CREATE,
	ROUTE_BRANDS,
	ROUTE_BRANDS_CREATE,
	ROUTE_BRANDS_EDIT,
	ROUTE_CUSTOMERS,
	ROUTE_CUSTOMERS_COPY,
	ROUTE_CUSTOMERS_CREATE,
	ROUTE_CUSTOMERS_DETAIL,
	ROUTE_CUSTOMERS_EDIT,
	ROUTE_MATCH_HISTORIES,
	ROUTE_MATCH_HISTORIES_FITTING,
	ROUTE_PLAYGROUND,
	ROUTE_PURCHASE,
	ROUTE_PURCHASE_NUMBER,
	ROUTE_SETTINGS,
	ROUTE_STORES,
	ROUTE_STORES_COPY,
	ROUTE_STORES_CREATE,
	ROUTE_STORES_EDIT,
	ROUTE_USERS_COPY,
	ROUTE_USERS_CREATE,
	ROUTE_USERS_EDIT,
	ROUTE_DRIVER_HEADS,
	ROUTE_DRIVER_HEADS_CREATE,
	ROUTE_DRIVER_HEADS_EDIT,
	ROUTE_DRIVER_SHAFTS,
	ROUTE_DRIVER_SHAFTS_CREATE,
	ROUTE_DRIVER_SHAFTS_COPY,
	ROUTE_DRIVER_SHAFTS_EDIT,
	ROUTE_DRIVER_HEADS_COPY,
	ROUTE_IRON_HEADS,
	ROUTE_IRON_SHAFTS,
	ROUTE_IRON_HEAD_CREATE,
	ROUTE_IRON_HEAD_EDIT,
	ROUTE_IRON_SHAFT_CREATE,
	ROUTE_IRON_SHAFT_EDIT,
	ROUTE_IRON_HEAD_COPY,
	ROUTE_IRON_SHAFT_COPY,
	ROUTE_BALLS_EDIT,
	ROUTE_BODYANALYSIS,
	ROUTE_BODYANALYSIS_DETAILS,
	// ROUTE_PAYMENTS,
	// ROUTE_PAYMENTS_DETAILS,
} from "urls"

const routes = [
	{
		name: "routes.customers",
		id: "customers",
		icon: "person",
		path: ROUTE_CUSTOMERS,
		lazy: () => import("layouts/customers/list"),
		access: ["admin"],
		routes: [
			{
				path: ROUTE_CUSTOMERS_CREATE,
				lazy: () =>
					import("layouts/customers/edit").then(({ CustomerCreate }) => ({
						Component: CustomerCreate,
					})),
				access: ["admin"],
			},
			{
				path: ROUTE_CUSTOMERS_COPY,
				lazy: () =>
					import("layouts/customers/edit").then(({ CustomerEdit }) => ({
						Component: () => <CustomerEdit copy />,
					})),
				access: ["admin"],
			},
			{
				path: ROUTE_CUSTOMERS_EDIT,
				lazy: () =>
					import("layouts/customers/edit").then(({ CustomerEdit }) => ({
						Component: () => <CustomerEdit />,
					})),
				access: ["admin"],
			},
			{
				path: ROUTE_CUSTOMERS_DETAIL,
				lazy: () => import("layouts/customers/details"),
				access: ["admin"],
			},
		],
	},
	{
		name: "routes.match-histories",
		id: "match-histories",
		icon: "history_outline",
		path: ROUTE_MATCH_HISTORIES,
		lazy: () => import("layouts/matchHistories/list"),
		access: ["admin"],
		routes: [
			{
				path: ROUTE_MATCH_HISTORIES_FITTING,
				lazy: () => import("layouts/matchHistories/detail"),
				access: ["admin"],
			},
		],
	},
	{
		name: "routes.brands",
		id: "brands",
		icon: <img src={BrandIcon} alt="Icon" className="icon-image" />,
		path: ROUTE_BRANDS,
		lazy: () => import("layouts/brands/list"),
		access: ["admin"],
		routes: [
			{
				path: ROUTE_BRANDS_CREATE,
				lazy: () => import("layouts/brands/create"),
				access: ["admin"],
			},
			{
				path: ROUTE_BRANDS_EDIT,
				lazy: () => import("layouts/brands/edit"),
				access: ["admin"],
			},
		],
	},
	{
		name: "routes.driver.driver",
		icon: "sports_golf",
		id: "driver",
		access: ["admin"],
		children: [
			{
				name: "routes.heads",
				icon: <img src={HeadIcon} alt="Icon" className="icon-image" />,
				path: ROUTE_DRIVER_HEADS,
				lazy: () => import("layouts/drivers/heads/list"),
				access: ["admin"],
				routes: [
					{
						path: ROUTE_DRIVER_HEADS_CREATE,
						lazy: () => import("layouts/drivers/heads/create"),
						access: ["admin"],
					},
					{
						path: ROUTE_DRIVER_HEADS_COPY,
						lazy: () =>
							import("layouts/drivers/heads/edit").then(({ Component: DriverHeadEdit }) => ({
								Component: () => <DriverHeadEdit copy />,
							})),
						access: ["admin"],
					},
					{
						path: ROUTE_DRIVER_HEADS_EDIT,
						lazy: () => import("layouts/drivers/heads/edit"),
						access: ["admin"],
					},
				],
			},
			{
				name: "routes.shafts",
				icon: <img src={ShaftIcon} alt="Icon" className="icon-image" />,
				path: ROUTE_DRIVER_SHAFTS,
				lazy: () => import("layouts/drivers/shafts/list"),
				access: ["admin"],
				routes: [
					{
						path: ROUTE_DRIVER_SHAFTS_CREATE,
						lazy: () => import("layouts/drivers/shafts/create"),
						access: ["admin"],
					},
					{
						path: ROUTE_DRIVER_SHAFTS_COPY,
						lazy: () =>
							import("layouts/drivers/shafts/edit").then(({ Component: DriverShaftEdit }) => ({
								Component: () => <DriverShaftEdit copy />,
							})),
						access: ["admin"],
					},
					{
						path: ROUTE_DRIVER_SHAFTS_EDIT,
						lazy: () => import("layouts/drivers/shafts/edit"),
						access: ["admin"],
					},
				],
			},
		],
	},
	{
		name: "routes.iron.iron",
		icon: "golf_course",
		id: "iron",
		access: ["admin"],
		children: [
			{
				name: "routes.heads",
				icon: <img src={HeadIcon} alt="Icon" className="icon-image" />,
				path: ROUTE_IRON_HEADS,
				lazy: () => import("layouts/iron/heads/list"),
				access: ["admin"],
				routes: [
					{
						path: ROUTE_IRON_HEAD_CREATE,
						lazy: () =>
							import("layouts/iron/heads/edit").then(({ IronHeadCreate }) => ({
								Component: IronHeadCreate,
							})),
						access: ["admin"],
					},
					{
						path: ROUTE_IRON_HEAD_EDIT,
						lazy: () =>
							import("layouts/iron/heads/edit").then(({ IronHeadEdit }) => ({
								Component: IronHeadEdit,
							})),
						access: ["admin"],
					},
					{
						path: ROUTE_IRON_HEAD_COPY,
						lazy: () =>
							import("layouts/iron/heads/edit").then(({ IronHeadEdit }) => ({
								Component: () => <IronHeadEdit copy />,
							})),
						access: ["admin"],
					},
				],
			},
			{
				name: "routes.shafts",
				icon: <img src={ShaftIcon} alt="Icon" className="icon-image" />,
				path: ROUTE_IRON_SHAFTS,
				lazy: () => import("layouts/iron/shafts/list"),
				access: ["admin"],
				routes: [
					{
						path: ROUTE_IRON_SHAFT_CREATE,
						lazy: () =>
							import("layouts/iron/shafts/edit").then(({ IronShaftsCreate }) => ({
								Component: IronShaftsCreate,
							})),
						access: ["admin"],
					},
					{
						path: ROUTE_IRON_SHAFT_EDIT,
						lazy: () =>
							import("layouts/iron/shafts/edit").then(({ IronShaftsEdit }) => ({
								Component: IronShaftsEdit,
							})),
						access: ["admin"],
					},
					{
						path: ROUTE_IRON_SHAFT_COPY,
						lazy: () =>
							import("layouts/iron/shafts/edit").then(({ IronShaftsEdit }) => ({
								Component: () => <IronShaftsEdit copy />,
							})),
						access: ["admin"],
					},
				],
			},
		],
	},
	{
		name: "routes.balls",
		id: "balls",
		icon: <img src={BallIcon} alt="Icon" className="icon-image" />,
		path: ROUTE_BALLS,
		lazy: () => import("layouts/balls/list"),
		access: ["admin"],
		routes: [
			{
				path: ROUTE_BALLS_CREATE,
				lazy: () => import("layouts/balls/create"),
				access: ["admin"],
			},
			{
				path: ROUTE_BALLS_EDIT,
				lazy: () => import("layouts/balls/edit"),
				access: ["admin"],
			},
		],
	},
	{
		name: "routes.stores",
		id: "stores",
		icon: "store_front",
		path: ROUTE_STORES,
		lazy: () => import("layouts/stores"),
		access: ["admin"],
		routes: [
			{
				path: ROUTE_STORES_CREATE,
				lazy: () => import("layouts/stores/create"),
				access: ["admin"],
			},
			{
				path: ROUTE_STORES_COPY,
				lazy: () => import("layouts/stores/copy"),
				access: ["admin"],
			},
			{
				path: ROUTE_STORES_EDIT,
				lazy: () => import("layouts/stores/edit"),
				access: ["admin"],
			},
		],
	},
	{
		name: "routes.purchase-history",
		id: "purchase-history",
		icon: <img src={PurchaseIcon} alt="Icon" className="icon-image" />,
		path: ROUTE_PURCHASE,
		lazy: () => import("layouts/purchaseHistory/list"),
		access: ["admin", "builder"],
		routes: [
			{
				path: ROUTE_PURCHASE_NUMBER,
				lazy: () => import("layouts/purchaseHistory/details"),
				access: ["admin", "builder"],
			},
		],
	},
	// {
	// 	name: "routes.payments",
	// 	id: "payments",
	// 	icon: "receipt",
	// 	path: ROUTE_PAYMENTS,
	// 	lazy: () => import("layouts/payments/list"),
	// 	access: ["admin"],
	// 	routes: [
	// 		{
	// 			route: ROUTE_PAYMENTS_DETAILS,
	// 			lazy: () => import("layouts/payments/detail"),
	// 			access: ["admin"],
	// 		},
	// 	],
	// },
	{
		name: "routes.bodyanalysis",
		id: "body-analysis",
		icon: "accessibility",
		path: ROUTE_BODYANALYSIS,
		lazy: () => import("layouts/bodyAnalysis/list"),
		access: ["admin"],
		routes: [
			{
				path: ROUTE_BODYANALYSIS_DETAILS,
				lazy: () => import("layouts/bodyAnalysis/detail"),
				access: ["admin"],
			},
		],
	},
	{
		name: "routes.users.users",
		id: "users",
		icon: "manage_accounts",
		access: ["admin"],
		children: [
			{
				name: "routes.users.admin",
				path: ROUTE_ADMIN,
				icon: "admin_panel_settings",
				lazy: () => import("layouts/users/admin/list"),
				access: ["admin"],
				routes: [
					{
						path: ROUTE_ADMIN_CREATE,
						lazy: () => import("layouts/users/admin/create"),
						access: ["admin"],
					},
					{
						path: ROUTE_ADMIN_EDIT,
						lazy: () => import("layouts/users/admin/edit"),
						access: ["admin"],
					},
				],
			},
			{
				name: "routes.users.app",
				path: ROUTE_USERS_APP,
				icon: "smartphone",
				lazy: () => import("layouts/users/app/list"),
				access: ["admin"],
				routes: [
					{
						path: ROUTE_USERS_CREATE,
						lazy: () =>
							import("layouts/users/app/create").then(({ AppUserCreate }) => ({
								Component: () => <AppUserCreate />,
							})),
						access: ["admin"],
					},
					{
						path: ROUTE_USERS_COPY,
						lazy: () =>
							import("layouts/users/app/create").then(({ AppUserCopy }) => ({
								Component: () => <AppUserCopy />,
							})),
						access: ["admin"],
					},
					{
						path: ROUTE_USERS_EDIT,
						lazy: () => import("layouts/users/app/edit"),
						access: ["admin"],
					},
				],
			},
		],
	},
	{
		name: "routes.settings",
		id: "settings",
		icon: "settings",
		path: ROUTE_SETTINGS,
		lazy: () => import("layouts/settings"),
		access: ["admin"],
	},
]

if (process.env.NODE_ENV === "development") {
	routes.push({
		name: "Playground",
		id: "playground",
		icon: "abc",
		path: ROUTE_PLAYGROUND,
		lazy: () => import("layouts/playground"),
		access: ["admin"],
	})
}

export default routes
