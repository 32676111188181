import { useTranslation } from "react-i18next"
import { useDispatch } from "react-redux"

import { openSnackbar } from "stores/snackbar"

export const formatCurrency = (number) =>
	Number(number).toLocaleString(undefined, { maximumFractionDigits: 2 })

export const getErrorMessage = (error) => {
	if (error.errors) {
		return Object.entries(error.errors).reduce((errors, [key, [value]]) => {
			errors[key] = { type: "server", message: value }

			return errors
		}, {})
	}

	return error.message
}

export const getErrorMessageForSimpleForm = (error) => {
	return Object.entries(error.errors).reduce((init, [k, c]) => {
		init[k] = c[0]
		return init
	}, {})
}

export const formatLongText = (text, limit) => {
	if (text.length > limit) {
		return `${text.slice(0, limit)}...`
	}
	return text
}

export function useStub() {
	const { t } = useTranslation()

	const stub = [{ name: t("Loading ..."), value: Math.random() }]

	return [stub, t]
}

export function useFormErrorHandler() {
	const { t } = useTranslation()
	const dispatch = useDispatch()

	return (error, setErrors) => {
		dispatch(openSnackbar("error", error.message || t("Something went wrong!")))

		const errors = getErrorMessage(error)

		if (typeof errors === "object") {
			Object.entries(errors).forEach(([key, value]) => {
				setErrors(key, value)
			})
		}
	}
}
