import ReactDOM from "react-dom"
import { Provider } from "react-redux"
import { PersistGate } from "redux-persist/lib/integration/react"
import CssBaseline from "@mui/material/CssBaseline"
import { CacheProvider } from "@emotion/react"
import createCache from "@emotion/cache"
import { ThemeProvider } from "@mui/material/styles"

import { MaterialUIControllerProvider } from "context"
import storeObject from "stores/store"
import theme from "assets/theme"
import App from "App"

import "./global.css"
import "./i18n"

const cache = createCache({
	key: "css",
	prepend: true,
})

ReactDOM.render(
	<MaterialUIControllerProvider>
		<Provider store={storeObject.store}>
			<PersistGate persistor={storeObject.persistor}>
				<CacheProvider value={cache}>
					<ThemeProvider theme={theme}>
						<CssBaseline />
						<App />
					</ThemeProvider>
				</CacheProvider>
			</PersistGate>
		</Provider>
	</MaterialUIControllerProvider>,
	document.getElementById("root")
)
