import axios from "axios"
import i18n from "i18next"

export const BASE_ADMIN_URL = "api/admin/v1"

export const customAxios = axios.create({
	baseURL: `${process.env.REACT_APP_BASE_URL}/${BASE_ADMIN_URL}`,
	withCredentials: true,
})

customAxios.defaults.headers = {
	...customAxios.defaults.headers,
	// needs to be a getter to make `Accept-Language` a computed value
	// as `i18n.language` can be update anytime by users
	get "Accept-Language"() {
		let lang = i18n.language || process.env.REACT_APP_DEFAULT_LANGUAGE

		if (lang === "kor") {
			lang = "ko"
		}

		return lang
	},
}

const UNAUTHORIZED_STATUSES = [419, 401, 403]

// interceptor for http
customAxios.interceptors.response.use(
	(response) => response,
	async (rawError) => {
		const response = rawError.response || {}

		if (UNAUTHORIZED_STATUSES.includes(response.status)) {
			await Promise.all([
				import("stores/store"),
				import("services/modules/auth"),
				import("stores/snackbar"),
			])
				.then(async (imports) => {
					const [{ store }, { default: authApi }, { openSnackbar }] = imports

					await store.dispatch(authApi.endpoints.logout.initiate())

					store.dispatch(
						openSnackbar(
							"info",
							"Your session has expired or you are trying to access an unauthorized URL. Logging out.",
							5000
						)
					)
				})
				.catch(console.error)
		}

		throw response.data || rawError
	}
)

export default customAxios
