export const API_LOGOUT = "/logout"
export const API_LOGIN = "/login"
export const API_ACCOUNT = "/account"
export const API_USERS = "/users"
export const API_FITTING_HISTORIES = "/fitting"
export const API_BALL_INFO = "/ball-info"
export const API_SITE_SETTINGS = "/site-setting"

export const API_APP_USERS = "/app-users"
export const API_APP_USERS_ALL = `${API_APP_USERS}/all`

export const API_BRANDS = "/brands"
export const API_BRANDS_ALL = `${API_BRANDS}/all`

export const API_CLUB_HEADS = "/club-heads"
export const API_CLUB_HEADS_ALL = `${API_CLUB_HEADS}/all`

export const API_SHAFTS = "/shafts"
export const API_SHAFTS_ALL = `${API_SHAFTS}/all`

export const API_DRIVER_SHAFTS = "/shafts"
export const API_DRIVER_SHAFT_FLEXES = `${API_DRIVER_SHAFTS}/flexes`

export const API_INSTOCK_HEADS = "/instock-heads/bulk-action"
export const API_INSTOCK_SHAFTS = "/instock-shafts/bulk-action"

export const API_STORES = "/stores"
export const API_STORES_LIST = `${API_STORES}/all`
export const API_STORE_COPY = `${API_STORES}/copy`

export const API_DEVICES = "/devices"

export const API_ANALYZERS = `/analyzers`
export const API_ANALYZERS_ALL = `${API_ANALYZERS}/all`

export const API_ORDERS = "/order"
export const API_ORDERS_RENTAL_OVERVIEW = `${API_ORDERS}/rental/overview`

export const API_COMMENTS = "/comment"
export const API_PURCHASES = "/purchase"
export const API_COMMENTS_PURCHASE = `${API_COMMENTS}${API_PURCHASES}`
export const API_PURCHASE_STATUSES = `${API_PURCHASES}/status-list`

export const API_IRON_HEADS = "/iron-heads"
export const API_IRON_GET_HEAD = API_IRON_HEADS
export const API_IRON_GET_HEADS_ALL = `${API_IRON_HEADS}/all`
export const API_IRON_HEADS_SYNC_STORES = `${API_IRON_HEADS}/sync-stores`

export const API_IRON_SHAFTS = "/iron-shafts"
export const API_IRON_GET_SHAFTS = API_IRON_SHAFTS
export const API_IRON_SHAFTS_LIST_ALL = `${API_IRON_SHAFTS}/all`
export const API_IRON_SHAFTS_SYNC_STORES = `${API_IRON_SHAFTS}/sync-stores`

export const API_BODYANALYSIS = "/body-analysis"

export const API_PAYMENT = "/payments"

export const API_STORE_MEMBERS = "/store-members"
