import { api } from "services/api"
import {
	API_COMMENTS,
	API_COMMENTS_PURCHASE,
	API_PURCHASE_STATUSES,
	API_PURCHASES,
} from "services/endpoints"
import {
	TAG_PURCHASE_HISTORY_COMMENTS,
	TAG_PURCHASE_HISTORY_COMMENT,
	TAG_PURCHASE_HISTORY,
} from "services/tags"
import { wellKnown } from "utils/format"

export default api("purchaseApi").injectEndpoints({
	endpoints(build) {
		return {
			getPurchaseComments: build.query({
				query(params) {
					return {
						params,
						url: API_COMMENTS_PURCHASE,
					}
				},

				providesTags: [TAG_PURCHASE_HISTORY_COMMENTS],
			}),

			addPurchaseComment: build.mutation({
				query(data) {
					return {
						data,
						method: "POST",
						url: API_COMMENTS,
					}
				},

				providesTags: [TAG_PURCHASE_HISTORY_COMMENT],

				invalidatesTags: (ok) => (ok ? [TAG_PURCHASE_HISTORY_COMMENTS] : []),
			}),

			getPurchaseStatuses: build.query({
				query() {
					return {
						method: "GET",
						url: API_PURCHASE_STATUSES,
					}
				},

				transformResponse: (data) =>
					data.status_keys.sort((left) => (left.startsWith("payment_") ? 0 : 1)),
			}),

			get: build.query({
				query(params) {
					return {
						params,
						url: API_PURCHASES,
					}
				},
			}),

			getById: build.query({
				query(purchaseNo) {
					return {
						url: `${API_PURCHASES}/${purchaseNo}`,
					}
				},

				transformResponse({ data }) {
					if (data.payment_transaction) {
						data.payment_transaction = Object.entries(data.payment_transaction).reduce(
							(init, [key, value]) => {
								init[key] = wellKnown(key, value)

								return init
							},
							{}
						)
					}

					if (data.refund_transaction) {
						data.refund_transaction = Object.entries(data.refund_transaction).reduce(
							(init, [key, value]) => {
								init[key] = wellKnown(key, value)

								return init
							},
							{}
						)
					}

					if (data.complete) {
						const c = data.complete

						data.complete = {
							amount: c.PCD_PAY_AMOUNT,
							card_name: c.PCD_PAY_CARDNAME,
							card_num: c.PCD_PAY_CARDNUM,
							created_at: c.created_at,
							email: c.PCD_PAYER_EMAIL,
							goods: c.PCD_PAY_GOODS,
							message: c.PCD_PAY_MSG,
							pay_code: c.PCD_PAY_CODE,
							payer: c.PCD_PAYER_NAME,
							payment_type: c.PCD_PAY_TYPE,
							phone: c.PCD_PAYER_HP,
							receipt: c.PCD_PAY_CARDRECEIPT,
							status: c.PCD_PAY_RST,
							total_tax_amount: c.PCD_PAY_TAXTOTAL,
							updated_at: c.updated_at,
						}
					} else {
						data.complete = {}
					}

					if (data.refund) {
						const r = data.refund

						data.refund = {
							amount: r.PCD_REFUND_TOTAL,
							created_at: r.created_at,
							goods: r.PCD_PAY_GOODS,
							message: r.PCD_PAY_MSG,
							payment_type: r.PCD_PAY_TYPE,
							receipt: r.PCD_PAY_CARDRECEIPT,
							status: r.PCD_PAY_RST,
							total_tax_amount: r.PCD_REFUND_TAXTOTAL,
							updated_at: r.updated_at,
						}
					}

					return data
				},

				providesTags: [TAG_PURCHASE_HISTORY],
			}),

			getPurchaseTypeByUser: build.query({
				query({ id, ...params }) {
					return {
						params,
						url: `${API_PURCHASES}?user_id=${id}&purchase_type=b`,
					}
				},
			}),

			getPurchaseType: build.query({
				query(params) {
					return {
						params,
						url: `${API_PURCHASES}?purchase_type=b`,
					}
				},
			}),

			updateStatus: build.mutation({
				async queryFn({ purchase_no, ...data }, queryApi, extraOptions, baseQuery) {
					const { data: response } = await baseQuery({
						data,
						method: "PUT",
						url: `${API_PURCHASES}/${purchase_no}/status`,
					})

					if (response.errorCode) {
						return {
							error: response,
						}
					}

					return { data: response }
				},

				invalidatesTags: (ok) => (ok ? [TAG_PURCHASE_HISTORY] : []),
			}),
		}
	},
})
