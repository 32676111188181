import Grid from "@mui/material/Grid"
import Link from "@mui/material/Link"
import Container from "@mui/material/Container"

import MDBox from "components/MDBox"
import MDTypography from "components/MDTypography"
import PageLayout from "components/LayoutContainers/PageLayout"
import typography from "assets/theme/base/typography"

export function BasicLayout({ image, children }) {
	return (
		<PageLayout>
			<MDBox
				position="absolute"
				width="100%"
				minHeight="100vh"
				sx={{
					backgroundImage: ({ functions: { linearGradient, rgba }, palette: { gradients } }) =>
						image &&
						`${linearGradient(
							rgba(gradients.dark.main, 0.6),
							rgba(gradients.dark.state, 0.6)
						)}, url(${image})`,
					backgroundSize: "cover",
					backgroundPosition: "center",
					backgroundRepeat: "no-repeat",
				}}
			/>
			<MDBox px={1} width="100%" height="100vh" mx="auto">
				<Grid container spacing={1} justifyContent="center" alignItems="center" height="100%">
					<Grid item xs={11} sm={9} md={5} lg={4} xl={3}>
						{children}
					</Grid>
				</Grid>
			</MDBox>
			<Footer light />
		</PageLayout>
	)
}

export function Footer({ light }) {
	const { size } = typography

	return (
		<MDBox position="absolute" width="100%" bottom={0} py={4}>
			<Container>
				<MDBox
					width="100%"
					display="flex"
					flexDirection={{ xs: "column", lg: "row" }}
					justifyContent="space-between"
					alignItems="center"
					px={1.5}
				>
					<MDBox
						display="flex"
						justifyContent="center"
						alignItems="center"
						flexWrap="wrap"
						color={light ? "white" : "text"}
						fontSize={size.sm}
					>
						&copy; {new Date().getFullYear()} Gen Fit X
					</MDBox>
					<MDBox
						component="ul"
						sx={({ breakpoints }) => ({
							display: "flex",
							flexWrap: "wrap",
							alignItems: "center",
							justifyContent: "center",
							listStyle: "none",
							mt: 3,
							mb: 0,
							p: 0,

							[breakpoints.up("lg")]: {
								mt: 0,
							},
						})}
					>
						<MDBox component="li" pr={2} lineHeight={1}>
							<Link href="https://www.dss.com.mm/" target="_blank">
								<MDTypography
									variant="button"
									fontWeight="regular"
									color={light ? "white" : "dark"}
								>
									Gen Fit X
								</MDTypography>
							</Link>
						</MDBox>
						<MDBox component="li" px={2} lineHeight={1}>
							<Link href="https://www.dss.com.mm" target="_blank">
								<MDTypography
									variant="button"
									fontWeight="regular"
									color={light ? "white" : "dark"}
								>
									About Us
								</MDTypography>
							</Link>
						</MDBox>
					</MDBox>
				</MDBox>
			</Container>
		</MDBox>
	)
}
