import { dobFormatter, formatDate } from "utils/dateTime"

// format know format into desired format intended to be used in common components
// where it renders different fields and individual field is unknown
export function wellKnown(name, value) {
	switch (name) {
		case "dob":
			return dobFormatter(value)

		case "created_at":
		case "updated_at":
		case /.created_at$/.test(name) ? name : Math.random():
		case /.updated_at$/.test(name) ? name : Math.random():
			return formatDate(value)

		case "purchased_at":
			return formatDate(value)

		default:
			return value
	}
}
