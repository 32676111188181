import { api } from "services/api"
import { API_CLUB_HEADS_ALL } from "services/endpoints"
import { TAG_CLUB_HEADS_ALL } from "services/tags"

export default api("clubsHeadsApi").injectEndpoints({
	endpoints(build) {
		return {
			listAll: build.query({
				query(params) {
					return {
						params,
						method: "GET",
						url: API_CLUB_HEADS_ALL,
					}
				},

				transformResponse: ({ data }) => {
					return data.map((item) => {
						const { id, name, loft_standard, loft_start, loft_end } = item

						return {
							...item,
							label: `${name} ${loft_start === loft_end ? `(${loft_standard})` : ""}`,
							head_name: name,
							head_id: id,
						}
					})
				},

				providesTags: [TAG_CLUB_HEADS_ALL],
			}),
		}
	},
})
