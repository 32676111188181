import { useState, useEffect } from "react"
import PropTypes from "prop-types"
import AppBar from "@mui/material/AppBar"
import Toolbar from "@mui/material/Toolbar"
import IconButton from "@mui/material/IconButton"
import Icon from "@mui/material/Icon"
import { useDispatch, useSelector } from "react-redux"

import MDBox from "components/MDBox"
import {
	navbar,
	navbarContainer,
	navbarRow,
	navbarMobileMenu,
} from "components/Navbars/DashboardNavbar/styles"
import { useMaterialUIController, setTransparentNavbar, setMiniSidenav } from "context"
import { useTranslation } from "react-i18next"
import MDSwitch from "components/MDSwtich"
import { KOREA_LANGUAGE, ENGLISH_LANGUAGE } from "constant"
import { changeLanguage } from "stores/app"

function DashboardNavbar({ absolute, light, isMini }) {
	const [navbarType, setNavbarType] = useState()
	const [controller, dispatch] = useMaterialUIController()
	const languageDispatch = useDispatch()
	const language = useSelector((state) => state.app.language)
	const { miniSidenav, transparentNavbar, fixedNavbar, darkMode } = controller
	const { i18n } = useTranslation()

	useEffect(() => {
		// Setting the navbar type
		if (fixedNavbar) {
			setNavbarType("sticky")
		} else {
			setNavbarType("static")
		}

		// sets the transparent state of the navbar.
		function handleTransparentNavbar() {
			setTransparentNavbar(dispatch, (fixedNavbar && window.scrollY === 0) || !fixedNavbar)
		}

		window.addEventListener("scroll", handleTransparentNavbar)

		// Call the handleTransparentNavbar function to set the state with the initial value.
		handleTransparentNavbar()

		// Remove event listener on cleanup
		return () => window.removeEventListener("scroll", handleTransparentNavbar)
	}, [dispatch, fixedNavbar])

	const handleMiniSidenav = () => setMiniSidenav(dispatch, !miniSidenav)

	// toggle change language
	const toggleLanguage = (e) => {
		if (e.target.checked) {
			i18n.changeLanguage(KOREA_LANGUAGE)
			languageDispatch(changeLanguage(KOREA_LANGUAGE))
		} else {
			i18n.changeLanguage(ENGLISH_LANGUAGE)
			languageDispatch(changeLanguage(ENGLISH_LANGUAGE))
		}
	}

	// Styles for the navbar icons
	const iconsStyle = ({ palette: { dark, white, text }, functions: { rgba } }) => ({
		color: () => {
			let colorValue = light || darkMode ? white.main : dark.main

			if (transparentNavbar && !light) {
				colorValue = darkMode ? rgba(text.main, 0.6) : text.main
			}

			return colorValue
		},
	})

	return (
		<AppBar
			position={absolute ? "absolute" : navbarType}
			color="inherit"
			sx={(theme) => navbar(theme, { transparentNavbar, absolute, light, darkMode })}
		>
			<Toolbar sx={(theme) => navbarContainer(theme)}>
				{isMini ? null : (
					<MDBox sx={(theme) => navbarRow(theme, { isMini })}>
						<MDBox color={light ? "white" : "inherit"}>
							<IconButton
								size="small"
								disableRipple
								color="inherit"
								sx={navbarMobileMenu}
								onClick={handleMiniSidenav}
							>
								<Icon sx={iconsStyle} fontSize="medium">
									{miniSidenav ? "menu" : "menu_open"}
								</Icon>
							</IconButton>
						</MDBox>
						<MDBox
							color="inherit"
							mb={{ xs: 1, md: 0 }}
							ml={3}
							sx={(theme) => navbarRow(theme, { isMini })}
						/>
					</MDBox>
				)}
				<MDSwitch
					onChange={toggleLanguage}
					checked={language === KOREA_LANGUAGE}
					suffixText={KOREA_LANGUAGE.toUpperCase()}
					prefixText={ENGLISH_LANGUAGE.toUpperCase()}
				/>
			</Toolbar>
		</AppBar>
	)
}

DashboardNavbar.defaultProps = {
	absolute: false,
	light: false,
	isMini: false,
}

DashboardNavbar.propTypes = {
	absolute: PropTypes.bool,
	light: PropTypes.bool,
	isMini: PropTypes.bool,
}

export default DashboardNavbar
