import i18n from "i18next"
import { initReactI18next } from "react-i18next"

import en from "locales/en"
import kor from "locales/kor"

const { REACT_APP_DEFAULT_LANGUAGE, NODE_ENV } = process.env

export const DEFAULT_LANG = NODE_ENV === "development" ? REACT_APP_DEFAULT_LANGUAGE || "en" : "kor"

i18n.use(initReactI18next).init({
	fallbackLng: NODE_ENV === DEFAULT_LANG,
	debug: NODE_ENV === "development",

	lng: DEFAULT_LANG,

	interpolation: {
		escapeValue: false,
		skipOnVariables: false,
	},

	resources: {
		en: { translation: en },
		kor: { translation: kor },
	},
})

export default i18n
