import { omit } from "lodash"

import { OMIT_ON_CLONE } from "constant"
import { api } from "services/api"
import { API_DRIVER_SHAFTS, API_DRIVER_SHAFT_FLEXES, API_INSTOCK_SHAFTS } from "services/endpoints"
import { TAG_DRIVER_SHAFT, TAG_DRIVER_SHAFTS, TAG_DRIVER_SHAFT_FLEXES } from "services/tags"

export default api("driverShaftsApi").injectEndpoints({
	endpoints(b) {
		return {
			list: b.query({
				query(params) {
					return {
						params,
						url: API_DRIVER_SHAFTS,
					}
				},

				transformResponse({ data, ...response }) {
					return {
						...response,
						data: data.map((item) => ({
							...item,
							name: item.shaft_name,
						})),
					}
				},

				providesTags: [TAG_DRIVER_SHAFTS],
			}),

			listFlex: b.query({
				query(params) {
					return {
						params,
						url: API_DRIVER_SHAFT_FLEXES,
					}
				},

				transformResponse({ data: { shaft_flexes } }) {
					return Object.entries(shaft_flexes).map(([name, value]) => {
						return { name, value }
					})
				},

				providesTags: [TAG_DRIVER_SHAFT_FLEXES],
			}),

			create: b.mutation({
				async queryFn({ formData, store_id_list }, queryApi, extraOptions, baseQuery) {
					const { data: response, error } = await baseQuery({
						data: omit(formData, OMIT_ON_CLONE),
						method: "POST",
						url: API_DRIVER_SHAFTS,
					})

					if (error) {
						return { error }
					}

					return await baseQuery({
						data: {
							store_id_list,
							shaft_id: response.data.id,
						},
						method: "POST",
						url: API_INSTOCK_SHAFTS,
					})
				},

				invalidatesTags: (ok) => (ok ? [TAG_DRIVER_SHAFTS] : []),
			}),

			get: b.query({
				query(id) {
					return {
						url: `${API_DRIVER_SHAFTS}/${id}`,
					}
				},

				transformResponse: ({ data }) => {
					return {
						...data,
						brandName: data.brand?.name ?? "",
						brand_id: data.brand?.id ?? "",
					}
				},

				providesTags: [TAG_DRIVER_SHAFT],
			}),

			update: b.mutation({
				async queryFn({ formData, store_id_list }, queryApi, extraOptions, baseQuery) {
					const { error } = await baseQuery({
						data: formData,
						method: "PUT",
						url: `${API_DRIVER_SHAFTS}/${formData.id}`,
					})

					if (error) {
						return { error }
					}

					return await baseQuery({
						data: {
							store_id_list,
							shaft_id: formData.id,
						},
						method: "POST",
						url: API_INSTOCK_SHAFTS,
					})
				},

				invalidatesTags: (ok) => (ok ? [TAG_DRIVER_SHAFT, TAG_DRIVER_SHAFTS] : []),
			}),

			delete: b.mutation({
				async queryFn({ id, ...data }, queryApi, extraOptions, baseQuery) {
					const { error } = await baseQuery({
						data,
						method: "POST",
						url: API_INSTOCK_SHAFTS,
					})

					if (error) {
						return { error }
					}

					return await baseQuery({
						method: "DELETE",
						url: `${API_DRIVER_SHAFTS}/${id}`,
					})
				},

				invalidatesTags: (ok) => (ok ? [TAG_DRIVER_SHAFTS] : []),
			}),

			toggleStatus: b.mutation({
				query({ id, is_active }) {
					return {
						method: "POST",
						data: { is_active },
						url: `${API_DRIVER_SHAFTS}/${id}/status`,
					}
				},

				invalidatesTags: (ok) => (ok ? [TAG_DRIVER_SHAFT, TAG_DRIVER_SHAFTS] : []),
			}),
		}
	},
})
