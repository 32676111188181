import { useTranslation } from "react-i18next"

import { api } from "services/api"
import { TAG_BRAND, TAG_BRANDS, TAG_BRANDS_ALL } from "services/tags"
import { API_BRANDS, API_BRANDS_ALL } from "services/endpoints"
import { handleError } from "utils/promiseHandler"

function normalize(data) {
	const type = data.type.reduce((init, item) => {
		if (init) {
			init = "both"
		} else {
			init = item.id
		}

		return init
	}, "")

	return { ...data, type }
}

export function useBrandsAsField() {
	const { t } = useTranslation()

	const { data = [], isFetching } = handleError(brandsApi.useListAllQuery, "common.errorWith", {
		name: "brands",
	})

	const brands = isFetching ? [{ name: t("Loading ..."), value: 0 }] : data

	return [brands, isFetching]
}

export const brandsApi = api("brandsApi").injectEndpoints({
	endpoints(build) {
		return {
			listAll: build.query({
				query(params) {
					return {
						params,
						method: "GET",
						url: API_BRANDS_ALL,
					}
				},

				providesTags: [TAG_BRANDS_ALL],

				transformResponse({ data }) {
					return Object.entries(data).map(([value, name]) => {
						return {
							name,
							value,
						}
					})
				},
			}),

			createBrand: build.mutation({
				query(data) {
					return {
						data: normalize(data),
						method: "POST",
						url: API_BRANDS,
					}
				},

				providesTags: [TAG_BRAND],

				invalidatesTags: (ok) => (ok ? [TAG_BRANDS] : []),
			}),

			delete: build.mutation({
				query(id) {
					return {
						method: "DELETE",
						url: `${API_BRANDS}/${id}`,
					}
				},

				invalidatesTags: (ok) => (ok ? [TAG_BRANDS] : []),
			}),

			fetchBrand: build.query({
				query({ id }) {
					return {
						method: "GET",
						url: `${API_BRANDS}/${id}`,
					}
				},

				transformResponse({ data }) {
					const type =
						data.type === "both" ? [{ id: "head" }, { id: "shaft" }] : [{ id: data.type }]

					return Object.assign(data, { type })
				},

				providesTags: [TAG_BRAND],
			}),

			updateBrand: build.mutation({
				query({ id, ...data }) {
					return {
						data: normalize(data),
						method: "PUT",
						url: `${API_BRANDS}/${id}`,
					}
				},

				invalidatesTags: (ok) => (ok ? [TAG_BRAND, TAG_BRANDS] : []),
			}),

			listBrands: build.query({
				query(params) {
					return {
						params,
						method: "GET",
						url: API_BRANDS,
					}
				},

				providesTags: [TAG_BRANDS],
			}),
		}
	},
})
