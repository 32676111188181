/**
=========================================================
* Material Dashboard 2 PRO React - v1.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-pro-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { forwardRef, useState } from "react"
import { styled } from "@mui/material/styles"
import LoadingButton from "@mui/lab/LoadingButton"
import PropTypes from "prop-types"

import MDButtonRoot, { style } from "components/MDButton/MDButtonRoot"
import { useMaterialUIController } from "context"

export const Wrapper = (Component) =>
	// eslint-disable-next-line react/prop-types, react/display-name
	forwardRef(({ color, variant, size, circular, iconOnly, children, ...rest }, ref) => {
		const [controller] = useMaterialUIController()
		const { darkMode } = controller

		return (
			<Component
				{...rest}
				ref={ref}
				color="primary"
				variant={variant === "gradient" ? "contained" : variant}
				size={size}
				ownerState={{ color, variant, size, circular, iconOnly, darkMode }}
			>
				{children}
			</Component>
		)
	})

const BaseLoadingButton = styled(LoadingButton)(style)

export const ImplicitLoadingButton = Wrapper(({ onClick, ...props }) => {
	const [loading, setLoading] = useState(false)

	return (
		<BaseLoadingButton
			loading={loading}
			onClick={async (event) => {
				try {
					setLoading(true)
					return await onClick(event)
				} finally {
					setLoading(false)
				}
			}}
			{...props}
		/>
	)
})

const MDButton = Wrapper(MDButtonRoot)

// Setting default values for the props of MDButton
MDButton.defaultProps = {
	size: "medium",
	variant: "contained",
	color: "white",
	circular: false,
	iconOnly: false,
}

// Typechecking props for the MDButton
MDButton.propTypes = {
	size: PropTypes.oneOf(["small", "medium", "large"]),
	variant: PropTypes.oneOf(["text", "contained", "outlined", "gradient"]),
	color: PropTypes.oneOf([
		"white",
		"primary",
		"secondary",
		"info",
		"success",
		"warning",
		"error",
		"light",
		"dark",
	]),
	circular: PropTypes.bool,
	iconOnly: PropTypes.bool,
	children: PropTypes.node.isRequired,
}

export default MDButton
