import {
	persistStore,
	persistReducer,
	FLUSH,
	REHYDRATE,
	PAUSE,
	PERSIST,
	PURGE,
	REGISTER,
} from "redux-persist"
import storage from "redux-persist/lib/storage"
import { configureStore, combineReducers } from "@reduxjs/toolkit"

import * as servicesModules from "services/modules"

import snackbar from "./snackbar"
import app from "./app"
import user from "./user"
import list from "./list"

const persistConfig = {
	key: "root",
	storage,
	whitelist: ["user", "app"],
}

const services = Object.values(servicesModules)

const persistedUserReducer = persistReducer({ key: "user", storage }, user)

const persistedAppReducer = persistReducer({ key: "app", storage }, app)

const reducers = combineReducers({
	snackbar,
	user: persistedUserReducer,
	app: persistedAppReducer,
	list: list(),
	...services.reduce(
		(init, module) => ({
			...init,
			[module.reducerPath]: module.reducer,
		}),
		{}
	),
})

const persistedReducer = persistReducer(persistConfig, reducers)

export const store = configureStore({
	reducer: persistedReducer,
	middleware: (defaultMiddleware) =>
		defaultMiddleware({
			serializableCheck: {
				ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
			},
		}).concat(services.map((service) => service.middleware)),
})

const persistor = persistStore(store)

export default { store, persistor }
