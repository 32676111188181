import { format, parseISO } from "date-fns"

import { api } from "services/api"
import { API_USERS } from "services/endpoints"
import {
	TAG_BODYANALYSIS_DETAILS,
	TAG_BODYANALYSIS_LIST,
	TAG_CUSTOMER,
	TAG_CUSTOMERS,
	TAG_MATCH_HISTORIES,
	TAG_MATCH_HISTORY,
} from "services/tags"
import { dateFormatter } from "utils/dateTime"
import { DATE_DEFAULT_FORMAT } from "constant"

function invalidatesList(success) {
	return success ? [TAG_CUSTOMERS] : []
}

export default api("customersApi").injectEndpoints({
	endpoints(b) {
		return {
			list: b.query({
				query(params) {
					return {
						params,
						url: API_USERS,
					}
				},

				providesTags: [TAG_CUSTOMERS],
			}),

			delete: b.mutation({
				query(id) {
					return {
						method: "DELETE",
						url: `${API_USERS}/${id}`,
					}
				},

				invalidatesTags: invalidatesList,
			}),

			get: b.query({
				query(id) {
					return {
						url: `${API_USERS}/${id}`,
					}
				},

				transformResponse({ data }) {
					data.dob = format(parseISO(data.dob), DATE_DEFAULT_FORMAT)
					data.gender = data.gender === "f" ? 0 : 1

					return data
				},

				providesTags: [TAG_CUSTOMER],
			}),

			create: b.mutation({
				query(data) {
					data.dob = dateFormatter({ date: data.dob, convertTo: "yyyy.MM.dd" })

					return {
						data,
						method: "POST",
						url: API_USERS,
					}
				},

				invalidatesTags: invalidatesList,
			}),

			update: b.mutation({
				query(data) {
					data.dob = dateFormatter({ date: data.dob, convertTo: "yyyy.MM.dd" })

					return {
						data,
						method: "PATCH",
						url: `${API_USERS}/${data.id}`,
					}
				},

				invalidatesTags: (ok) =>
					ok
						? [
								TAG_CUSTOMER,
								TAG_CUSTOMERS,
								TAG_MATCH_HISTORY,
								TAG_MATCH_HISTORIES,
								TAG_BODYANALYSIS_DETAILS,
								TAG_BODYANALYSIS_LIST,
						  ]
						: [],
			}),
		}
	},
})
