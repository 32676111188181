import get from "lodash/get"
import omit from "lodash/omit"

import { OMIT_ON_CLONE } from "constant"
import { api } from "services/api"
import {
	API_IRON_GET_HEAD,
	API_IRON_GET_HEADS_ALL,
	API_IRON_HEADS,
	API_IRON_HEADS_SYNC_STORES,
} from "services/endpoints"
import { TAG_IRON_HEAD, TAG_IRON_HEADS, TAG_IRON_HEADS_ALL } from "services/tags"

function invalidatesTags(success) {
	return success ? [TAG_IRON_HEADS] : []
}

function common(baseQuery, data) {
	return baseQuery({
		data,
		method: "POST",
		url: API_IRON_HEADS_SYNC_STORES,
	})
}

export const ironHeadsApi = api("ironHeadsApi").injectEndpoints({
	endpoints(b) {
		return {
			list: b.query({
				query(params) {
					return {
						params,
						method: "GET",
						url: API_IRON_HEADS,
					}
				},

				providesTags: [TAG_IRON_HEADS],
			}),

			listAll: b.query({
				query() {
					return {
						method: "GET",
						url: API_IRON_GET_HEADS_ALL,
					}
				},

				providesTags: [TAG_IRON_HEADS_ALL],
			}),

			create: b.mutation({
				async queryFn({ store_ids, ...data }, queryApi, extraOptions, baseQuery) {
					const { data: res, error } = await baseQuery({
						data: omit(data, OMIT_ON_CLONE),
						method: "POST",
						url: API_IRON_HEADS,
					})

					if (error) {
						return { error }
					}

					return await common(baseQuery, {
						head_id: res.data.id,
						store_id_list: store_ids,
					})
				},

				invalidatesTags,
			}),

			get: b.query({
				query(id) {
					return {
						method: "GET",
						url: `${API_IRON_GET_HEAD}/${id}`,
					}
				},

				transformResponse({ data }) {
					return {
						...data,
						is_active: data.is_active === 1,
						authorized: data.authorized === 1,
						brand_id: get(data.brand, "id"),
						photo: get(data.photos, "[0].path"),
					}
				},

				providesTags: [TAG_IRON_HEAD],
			}),

			update: b.mutation({
				async queryFn({ store_ids, id, ...data }, queryApi, extraOptions, baseQuery) {
					if (!data.photo?.startsWith("data:")) {
						delete data.photo
					}

					const { error } = await baseQuery({
						data,
						method: "PUT",
						url: `${API_IRON_GET_HEAD}/${id}`,
					})

					if (error) {
						return { error }
					}

					return await common(baseQuery, {
						head_id: id,
						store_id_list: store_ids,
					})
				},

				invalidatesTags: (ok) => (ok ? [TAG_IRON_HEAD, TAG_IRON_HEADS] : []),
			}),

			delete: b.mutation({
				async queryFn(id, queryApi, extraOptions, baseQuery) {
					const { error } = await common(baseQuery, {
						head_id: id,
						store_id_list: [],
					})

					if (error) {
						return { error }
					}

					return await baseQuery({
						method: "DELETE",
						url: `${API_IRON_HEADS}/${id}`,
					})
				},

				invalidatesTags,
			}),

			toggleActive: b.mutation({
				query({ id, ...data }) {
					return {
						data,
						method: "POST",
						url: `${API_IRON_HEADS}/${id}/status`,
					}
				},

				invalidatesTags: (ok) => (ok ? [TAG_IRON_HEAD, TAG_IRON_HEADS] : []),
			}),

			toggleAuthorize: b.mutation({
				query({ id, ...data }) {
					return {
						data,
						method: "POST",
						url: `${API_IRON_HEADS}/${id}/authorize`,
					}
				},

				invalidatesTags: (ok) => (ok ? [TAG_IRON_HEAD, TAG_IRON_HEADS] : []),
			}),
		}
	},
})
