import { api } from "services/api"
import { API_APP_USERS, API_APP_USERS_ALL } from "services/endpoints"
import { TAG_APP_USER, TAG_APP_USERS, TAG_APP_USERS_ALL } from "services/tags"

export default api("appUserApi").injectEndpoints({
	endpoints(build) {
		return {
			list: build.query({
				query(params) {
					return {
						params,
						method: "GET",
						url: API_APP_USERS,
					}
				},

				providesTags: [TAG_APP_USERS],
			}),

			listAll: build.query({
				query(params) {
					return {
						params,
						method: "GET",
						url: API_APP_USERS_ALL,
					}
				},

				transformResponse({ data }) {
					return data.map((user) => {
						const ret = {
							name: user.name,
							value: user.id,
							// app_user_id: user.id,
							// app_user: user.name,
						}

						return ret
					})
				},

				providesTags: [TAG_APP_USERS_ALL],
			}),

			create: build.mutation({
				query(data) {
					return {
						data,
						method: "POST",
						url: API_APP_USERS,
					}
				},

				invalidatesTags: (ok) => (ok ? [TAG_APP_USERS, TAG_APP_USER] : []),
			}),

			get: build.query({
				query(id) {
					return {
						method: "GET",
						url: `${API_APP_USERS}/${id}`,
					}
				},

				transformResponse({ data: { storeMembers, ...data } }) {
					if (storeMembers) {
						data.stores = storeMembers.map(({ store, ...item }) => {
							const ret = {
								...store,
								...item,
								store_name: store.name,
								app_user_id: data.id,
								app_user: data.name,
							}

							return ret
						})
					} else {
						data.stores = []
					}

					return data
				},

				providesTags: [TAG_APP_USER],
			}),

			update: build.mutation({
				query(data) {
					return {
						data,
						method: "PATCH",
						url: `${API_APP_USERS}/${data.id}`,
					}
				},

				invalidatesTags: (ok) => (ok ? [TAG_APP_USERS, TAG_APP_USER] : []),
			}),

			delete: build.mutation({
				query(id) {
					return {
						method: "DELETE",
						url: `${API_APP_USERS}/${id}`,
					}
				},

				invalidatesTags: (ok) => (ok ? [TAG_APP_USERS] : []),
			}),
		}
	},
})
