export const ROUTE_CUSTOMERS = "/customers"
export const ROUTE_CUSTOMERS_CREATE = `${ROUTE_CUSTOMERS}/create`
export const ROUTE_CUSTOMERS_COPY = `${ROUTE_CUSTOMERS_CREATE}/copy/:id`
export const ROUTE_CUSTOMERS_EDIT = `${ROUTE_CUSTOMERS}/edit/:id`
export const ROUTE_CUSTOMERS_DETAIL = `${ROUTE_CUSTOMERS}/detail/:id`

export const ROUTE_MATCH_HISTORIES = "/match-histories"
export const ROUTE_MATCH_HISTORIES_FITTING = `${ROUTE_MATCH_HISTORIES}/:id`

export const ROUTE_BRANDS = "/brands"
export const ROUTE_BRANDS_CREATE = `${ROUTE_BRANDS}/create`
export const ROUTE_BRANDS_COPY = `${ROUTE_BRANDS_CREATE}/copy/:id`
export const ROUTE_BRANDS_EDIT = `${ROUTE_BRANDS}/edit/:id`

export const ROUTE_DRIVER = "/driver"

export const ROUTE_DRIVER_HEADS = `${ROUTE_DRIVER}/heads`
export const ROUTE_DRIVER_HEADS_CREATE = `${ROUTE_DRIVER_HEADS}/create`
export const ROUTE_DRIVER_HEADS_COPY = `${ROUTE_DRIVER_HEADS_CREATE}/copy/:id`
export const ROUTE_DRIVER_HEADS_EDIT = `${ROUTE_DRIVER_HEADS}/edit/:id`

export const ROUTE_DRIVER_SHAFTS = `${ROUTE_DRIVER}/shafts`
export const ROUTE_DRIVER_SHAFTS_CREATE = `${ROUTE_DRIVER_SHAFTS}/create`
export const ROUTE_DRIVER_SHAFTS_COPY = `${ROUTE_DRIVER_SHAFTS_CREATE}/copy/:id`
export const ROUTE_DRIVER_SHAFTS_EDIT = `${ROUTE_DRIVER_SHAFTS}/edit/:id`

export const ROUTE_BALLS = "/balls"
export const ROUTE_BALLS_CREATE = `${ROUTE_BALLS}/create`
export const ROUTE_BALLS_EDIT = `${ROUTE_BALLS}/edit/:id`

export const ROUTE_STORES = "/stores"
export const ROUTE_STORES_CREATE = `${ROUTE_STORES}/create`
export const ROUTE_STORES_COPY = `${ROUTE_STORES_CREATE}/copy/:id`
export const ROUTE_STORES_EDIT = `${ROUTE_STORES}/edit/:id`

export const ROUTE_PURCHASE = "/purchase-history"
export const ROUTE_PURCHASE_NUMBER = `${ROUTE_PURCHASE}/:id`

export const ROUTE_USERS = "/users"
export const ROUTE_USERS_APP = `${ROUTE_USERS}/app`
export const ROUTE_USERS_CREATE = `${ROUTE_USERS_APP}/create`
export const ROUTE_USERS_COPY = `${ROUTE_USERS_CREATE}/copy/:id`
export const ROUTE_USERS_EDIT = `${ROUTE_USERS_APP}/edit/:id`

export const ROUTE_ADMIN = `${ROUTE_USERS}/admin`
export const ROUTE_ADMIN_CREATE = `${ROUTE_ADMIN}/create`
export const ROUTE_ADMIN_EDIT = `${ROUTE_ADMIN}/edit/:id`

export const ROUTE_SETTINGS = "/settings"

export const ROUTE_PLAYGROUND = "/playground"

export const ROUTE_LOGIN = "/login"

export const ROUTE_IRON = "/iron"
export const ROUTE_IRON_HEADS = `${ROUTE_IRON}/heads`
export const ROUTE_IRON_HEAD_EDIT = `${ROUTE_IRON_HEADS}/edit/:id`
export const ROUTE_IRON_HEAD_CREATE = `${ROUTE_IRON_HEADS}/create`
export const ROUTE_IRON_HEAD_COPY = `${ROUTE_IRON_HEAD_CREATE}/copy/:id`

export const ROUTE_IRON_SHAFTS = `${ROUTE_IRON}/shafts`
export const ROUTE_IRON_SHAFT_CREATE = `${ROUTE_IRON_SHAFTS}/create`
export const ROUTE_IRON_SHAFT_EDIT = `${ROUTE_IRON_SHAFTS}/edit/:id`
export const ROUTE_IRON_SHAFT_COPY = `${ROUTE_IRON_SHAFT_CREATE}/copy/:id`

export const ROUTE_BODYANALYSIS = "/bodyanalysis"
export const ROUTE_BODYANALYSIS_DETAILS = `${ROUTE_BODYANALYSIS}/:id`

export const ROUTE_PAYMENTS = "/payments"
export const ROUTE_PAYMENTS_DETAILS = `${ROUTE_PAYMENTS}/:id`

export function urlOfRoute(url, replacements = {}) {
	const parsed = url
		.split("/")
		.filter((part) => part)
		.map((part) => (part.startsWith(":") ? replacements[part.slice(1)] ?? part : part))
		.join("/")

	return `/${parsed}`
}

export const ROUTE_DEFAULT = ROUTE_CUSTOMERS
