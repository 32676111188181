import { Fragment, useEffect, useState } from "react"
import { NavLink, useLocation } from "react-router-dom"
import PropTypes from "prop-types"
import Divider from "@mui/material/Divider"
import Icon from "@mui/material/Icon"
import Collapse from "@mui/material/Collapse"
import ExpandLess from "@mui/icons-material/ExpandLess"
import ExpandMore from "@mui/icons-material/ExpandMore"
import { useTranslation } from "react-i18next"
import Grid from "@mui/material/Grid"
import { useDispatch } from "react-redux"

import MDBox from "components/MDBox"
import MDTypography from "components/MDTypography"
import {
	useMaterialUIController,
	setMiniSidenav,
	setTransparentSidenav,
	setWhiteSidenav,
} from "context"
import Logout from "layouts/authentication/logout"
import { useUser } from "stores/user"
import routes from "routes"
import { ROUTE_DEFAULT } from "urls"
import { clearAll } from "stores/list"

import SidenavCollapse from "./SidenavCollapse"
import SidenavRoot from "./SidenavRoot"
import sidenavLogoLabel from "./styles/sidenav"

// we pass this env in build/start script
// https://docs.npmjs.com/cli/v8/using-npm/scripts?v=true#packagejson-vars
const APP_VERSION = `v${process.env.REACT_APP_GENFITX_ADMIN_VERSION}`

function Sidenav(props) {
	const user = useUser()
	const { t } = useTranslation()
	const [controller, trigger] = useMaterialUIController()
	const { miniSidenav, transparentSidenav, whiteSidenav, darkMode } = controller

	const dispatch = useDispatch()

	const [openMenu, setOpenMenu] = useState(null)

	const { pathname } = useLocation()

	let textColor = "white"

	if (transparentSidenav || (whiteSidenav && !darkMode)) {
		textColor = "dark"
	} else if (whiteSidenav && darkMode) {
		textColor = "inherit"
	}

	const closeSidenav = () => setMiniSidenav(trigger, true)

	useEffect(() => {
		// A function that sets the mini state of the sidenav.
		function handleMiniSidenav() {
			setMiniSidenav(trigger, window.innerWidth < 1200)
			setTransparentSidenav(trigger, window.innerWidth < 1200 ? false : transparentSidenav)
			setWhiteSidenav(trigger, window.innerWidth < 1200 ? false : whiteSidenav)
		}

		window.addEventListener("resize", handleMiniSidenav)

		// Call the handleMiniSidenav function to set the state with the initial value.
		handleMiniSidenav()

		// Remove event listener on cleanup
		return () => window.removeEventListener("resize", handleMiniSidenav)
	}, [trigger])

	const translate = (key) => t(key, { value: "$t(routes.management)" })

	// Render all the routes from the routes.js (All the visible items on the Sidenav)
	const menuItems = routes
		.filter(({ access }) => access.includes(user.role))
		.map(({ name, icon, id, path, children }) => {
			const isOpen = openMenu === id
			return (
				<Fragment key={name + id + path}>
					{Array.isArray(children) ? (
						<>
							<SidenavCollapse
								name={translate(name)}
								path={path}
								icon={icon}
								active={pathname.startsWith(`/${id}`)}
								endIcon={isOpen ? <ExpandLess /> : <ExpandMore />}
								onClick={(event) => {
									event.preventDefault()

									setOpenMenu((menu) => {
										return menu === id ? null : id
									})
								}}
							/>
							<Collapse sx={{ pl: 3 }} in={isOpen}>
								{children.map((item) => {
									return (
										<SidenavCollapse
											key={item.path}
											path={item.path}
											name={translate(item.name)}
											icon={item.icon}
											active={pathname.startsWith(item.path)}
											onClick={() => {
												dispatch(clearAll())
											}}
										/>
									)
								})}
							</Collapse>
						</>
					) : (
						<SidenavCollapse
							onClick={() => {
								dispatch(clearAll())
								setOpenMenu((menu) => {
									return menu === id ? null : id
								})
							}}
							active={pathname.startsWith(path)}
							path={path}
							name={translate(name)}
							icon={icon}
						/>
					)}
				</Fragment>
			)
		})

	return (
		<SidenavRoot
			{...props}
			variant="permanent"
			ownerState={{ transparentSidenav, whiteSidenav, miniSidenav, darkMode }}
			sx={(theme) => {
				const pxToRem = theme.functions.pxToRem

				return {
					width: miniSidenav ? (window.innerWidth < 1200 ? 0 : pxToRem(96)) : pxToRem(250),
				}
			}}
		>
			<MDBox pt={3} pb={1} px={4} textAlign="center">
				<MDBox
					display={{ xs: "block", xl: "none" }}
					position="absolute"
					top={0}
					right={0}
					p={1.625}
					onClick={closeSidenav}
					sx={{ cursor: "pointer" }}
				>
					<MDTypography variant="h6" color="light">
						<Icon sx={{ fontWeight: "bold" }}>close</Icon>
					</MDTypography>
				</MDBox>
				<MDBox component={NavLink} to={ROUTE_DEFAULT} display="flex" alignItems="center">
					<MDBox width="100%" sx={(theme) => sidenavLogoLabel(theme, { miniSidenav })}>
						<MDTypography component="h6" variant="button" fontWeight="medium" color={textColor}>
							Gen Fit X {APP_VERSION}
						</MDTypography>
					</MDBox>
				</MDBox>
			</MDBox>
			<Divider
				light={
					(!darkMode && !whiteSidenav && !transparentSidenav) ||
					(darkMode && !transparentSidenav && whiteSidenav)
				}
			/>
			<Grid>{menuItems}</Grid>
			<MDBox p={2} mt="auto">
				<Logout />
			</MDBox>
		</SidenavRoot>
	)
}

// Setting default values for the props of Sidenav
Sidenav.defaultProps = {
	color: "info",
	brand: "",
}

// Typechecking props for the Sidenav
Sidenav.propTypes = {
	color: PropTypes.oneOf(["primary", "secondary", "info", "success", "warning", "error", "dark"]),
	brand: PropTypes.string,
}

export default Sidenav
