import { createApi } from "@reduxjs/toolkit/query/react"

import * as Tags from "./tags"
import customAxios from "./customAxios"

export const api = (reducerPath) =>
	createApi({
		reducerPath,
		// seems un-necessary to do this but we have to do this
		// if we want to preserve error respose from the server.
		// Notice the error return
		baseQuery: async (args) => {
			try {
				const { data } = await customAxios(args)

				return { data }
			} catch (error) {
				return { error }
			}
		},
		tagTypes: Object.values(Tags),
		endpoints: () => ({}),
	})
