import { format, parse } from "date-fns"

export const dateFormatter = ({ date, convertTo = "yy.MM.dd" }) => {
	return format(new Date(date), convertTo)
}

export const stringToDate = ({ dateString, formatFrom = "yy.MM.dd", formatTo }) => {
	const date = parse(dateString, formatFrom, new Date())
	return format(date, formatTo)
}

export const formatDate = (date, output = "yyyy.MM.dd HH:mm") => {
	try {
		return format(new Date(date), output)
	} catch (err) {
		return date
	}
}

export function defaultDateFormat(date, output = "yyyy.MM.dd") {
	try {
		return format(new Date(date), output)
	} catch (err) {
		return date
	}
}

export const dobFormatter = defaultDateFormat
