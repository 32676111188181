import { api } from "services/api"
import { API_BALL_INFO } from "services/endpoints"
import { TAG_BALLS, TAG_BALL } from "services/tags"

const invalidatesTags = (success) => (success ? [TAG_BALLS] : [])

export default api("ballsApi").injectEndpoints({
	endpoints(b) {
		return {
			list: b.query({
				query(params) {
					return {
						params,
						url: API_BALL_INFO,
					}
				},

				providesTags: [TAG_BALLS],
			}),

			delete: b.mutation({
				query(id) {
					return {
						method: "DELETE",
						url: `${API_BALL_INFO}/${id}`,
					}
				},

				invalidatesTags,
			}),

			get: b.query({
				query(id) {
					return {
						url: `${API_BALL_INFO}/${id}`,
					}
				},

				providesTags: [TAG_BALL],
			}),

			create: b.mutation({
				query(data) {
					return {
						data,
						method: "POST",
						url: API_BALL_INFO,
					}
				},

				invalidatesTags,
			}),

			update: b.mutation({
				query({ id, ...data }) {
					return {
						data,
						method: "PATCH",
						url: `${API_BALL_INFO}/${id}`,
					}
				},

				invalidatesTags,
			}),
		}
	},
})
