import { api } from "services/api"
import { API_SHAFTS_ALL } from "services/endpoints"
import { TAG_SHAFTS_ALL } from "services/tags"

export default api("clubsShaftsApi").injectEndpoints({
	endpoints(build) {
		return {
			listAll: build.query({
				query(params) {
					return {
						params,
						method: "GET",
						url: API_SHAFTS_ALL,
					}
				},

				transformResponse: ({ data }) => {
					return data.map((item) => {
						const { id, shaft_name, shaft_flex } = item

						return {
							...item,
							label: `${shaft_name} (${shaft_flex})`,
							shaft_id: id,
							shaft_name,
						}
					})
				},

				providesTags: [TAG_SHAFTS_ALL],
			}),
		}
	},
})
